const { SlotsProvider } = require('nano-slots');
const { Helmet } = require('react-helmet');
const countries = require('i18n-iso-countries');
const enCountries = require('i18n-iso-countries/langs/en.json');

const { NotificationsContainer } = require('@ui/Notifications');

require('./src/shared/styles/style.scss');

// exports.onClientEntry = () => {
// 	window.onload = () => {
// 		ReactGA.initialize([
// 			{
// 				trackingId: 'G-2Z65XL3FZB',
// 				gaOptions: {
// 					'Cache-Control': 'max-age = 86400',
// 					cookieExpires: 86400,
// 				},
// 			},
// 		]);
// 	};
// };

exports.wrapRootElement = ({ element }) => {
  countries.registerLocale(enCountries);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://www.google.com/" crossOrigin />
        <link
          rel="preconnect"
          href="https://stats.g.doubleclick.net"
          crossOrigin
        />
        <link
          rel="preconnect"
          href="https://www.google-analytics.com/"
          crossOrigin
        />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com/"
          crossOrigin
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
          integrity="sha512-yHknP1/AwR+yx26cB1y0cjvQUMvEa2PFzt1c9LlS4pRQ5NOTZFWbhBig+X9G9eYW/8m0/4OXNx8pxJ6z57x0dw=="
          crossOrigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
          integrity="sha512-17EgCFERpgZKcm0j0fEq1YCJuyAWdz9KUtv1EjVuaOz8pDnh/0nZxmU6BBXwaaxqoi9PQXnRWqlcDB027hgv9A=="
          crossOrigin="anonymous"
          referrerpolicy="no-referrer"
        />
      </Helmet>
      {element}
    </>
  );
};

exports.wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <NotificationsContainer />
    </>
  );
};
