import clsx from 'clsx';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './style.scss';

const Notifications = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
      closeButton={false}
      transition={Zoom}
      toastClassName={({ type }) => clsx(
        'rounded-[4px] border-[1px] border-solid',
        type === 'error' && 'border-danger-1 bg-danger-2',
        type === 'success' && 'border-success-1 bg-success-2',
      )}
    />
  );
};

export default Notifications;
